<template>
  <div class="popup">
    <div class="h2 popup__title">{{ $t("account-removed") }}</div>
    <div class="popup__buttons--welcome">
      <div class="popup__item">
        <InputButton :text="$t('go-to-login')" action="button" @click="logOutAfterDelete"></InputButton>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DeleteAccountPopup",
  methods: {
    async logOutAfterDelete() {
      window.localStorage.removeItem("token");
      window.localStorage.removeItem("demoLesson");
      window.sessionStorage.removeItem("pkce_code_verifier");
      window.sessionStorage.removeItem("pkce_state");
      window.localStorage.setItem("logout", Date.now());
      this.$emit("close");
      window.location.replace(process.env.VUE_APP_AUTH_URL + "/login");
    }
  }
};
</script>
