<template>
  <validation-provider
    ref="validationProvider"
    slim
    name="avatar"
    rules="required|image|maxdimensions"
    v-slot="{ errors }"
  >
    <form class="settings-form__container">
      <div class="settings-form__desc">{{ $t("settings-edit-your-profile") }} {{ fullName }}</div>

      <div class="settings-form__row">
        <label class="settings-form__label">
          <div class="settings-form__avatar">
            <img :src="getAvatar" :alt="`${fullName} image`" />
          </div>
          <div class="settings-form__button--avatar">{{ $t("settings-change-avatar") }}</div>
          <input accept="image" type="file" @change="onSubmitAvatar" />
        </label>
        <div class="settings-form__error-message">{{ errors[0] }}</div>
      </div>
    </form>
  </validation-provider>
</template>

<script>
import errorHandler from "../../helpers/ErrorHandler";
import client from "../../helpers/client";
import { mapState, mapMutations } from "vuex";

export default {
  name: "ProfileSettingsForm",
  data() {
    return {
      error: false
    };
  },
  computed: {
    getAvatar() {
      return this.avatar ?? "/default-user-avatar-thumb.jpg";
    },
    ...mapState("userStore", ["id", "avatar", "fullName"])
  },
  methods: {
    async onSubmitAvatar(event) {
      const formData = new FormData();
      const changeAvatar = event.target.files[0];
      const { valid } = await this.$refs.validationProvider.validate(changeAvatar);

      formData.append("user_id", this.id);
      formData.append("avatar", changeAvatar);

      if (valid) {
        try {
          const {
            data: {
              data: { url }
            }
          } = await client.post("/api/account/changeAvatar", formData);
          this.setUserAvatar({ avatar: url });
          this.$toast.success(this.$t("avatar.success"));
        } catch (err) {
          if (err.response) {
            const { errors } = err.response.data;

            errorHandler(errors, this.$refs.form);
          }
        }
      }
    },
    ...mapMutations("userStore", ["setUserAvatar"])
  }
};
</script>
