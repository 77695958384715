var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form-input"},[_c('ValidationProvider',{ref:"provider",attrs:{"vid":_vm.name,"name":_vm.placeholder,"rules":_vm.rules,"slim":"","mode":"lazy"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-input__wrapper"},[((_vm.inputType)==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.innerValue),expression:"innerValue"}],staticClass:"form-input__field",class:{ 'has-error': errors.length },attrs:{"aria-label":_vm.placeholder,"placeholder":_vm.placeholder,"name":_vm.name,"type":"checkbox"},domProps:{"value":_vm.value,"checked":Array.isArray(_vm.innerValue)?_vm._i(_vm.innerValue,_vm.value)>-1:(_vm.innerValue)},on:{"input":function($event){return _vm.$emit('input', {
            name: _vm.name,
            value: $event.target.value
          })},"change":function($event){var $$a=_vm.innerValue,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.value,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.innerValue=$$a.concat([$$v]))}else{$$i>-1&&(_vm.innerValue=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.innerValue=$$c}}}}):((_vm.inputType)==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.innerValue),expression:"innerValue"}],staticClass:"form-input__field",class:{ 'has-error': errors.length },attrs:{"aria-label":_vm.placeholder,"placeholder":_vm.placeholder,"name":_vm.name,"type":"radio"},domProps:{"value":_vm.value,"checked":_vm._q(_vm.innerValue,_vm.value)},on:{"input":function($event){return _vm.$emit('input', {
            name: _vm.name,
            value: $event.target.value
          })},"change":function($event){_vm.innerValue=_vm.value}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.innerValue),expression:"innerValue"}],staticClass:"form-input__field",class:{ 'has-error': errors.length },attrs:{"aria-label":_vm.placeholder,"placeholder":_vm.placeholder,"name":_vm.name,"type":_vm.inputType},domProps:{"value":_vm.value,"value":(_vm.innerValue)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.innerValue=$event.target.value},function($event){return _vm.$emit('input', {
            name: _vm.name,
            value: $event.target.value
          })}]}}),_c('span',{staticClass:"form-input__icon",class:{ 'form-input__icon--pointer': _vm.secondIcon },on:{"click":_vm.changePasswordVisibility}},[_c('inline-svg',{attrs:{"src":require(("../../assets/icons/" + (_vm.showInputText ? _vm.secondIcon : _vm.icon) + ".svg")),"fill":"currentColor","aria-label":_vm.name}})],1)]),(errors)?_c('div',{staticClass:"form-input__error"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }