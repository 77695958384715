<template>
  <div>
    <ValidationObserver v-slot="{ handleSubmit }" ref="form">
      <form v-if="email" @submit.prevent="handleSubmit(onSubmitEmail)" class="settings-form__container">
        <div class="settings-form__desc">
          {{ $t("settings-current-email") }}: <b>{{ email }}</b>
        </div>
        <div class="settings-form__desc">
          {{ $t("settings-change-email") }}
        </div>
        <div class="settings-form__row">
          <div class="settings-form__input-fields">
            <div class="settings-form__input-field">
              <InputText
                type="email"
                icon="mail"
                name="email"
                :placeholder="$t('e-mail-address')"
                @input="setValue"
                rules="required"
              />
            </div>
          </div>
          <div class="settings-form__button">
            <InputButton :loading="loading" :text="$t('settings-change-button')" action="submit"></InputButton>
          </div>
        </div>
      </form>
      <div class="global-loader global-loader--primary global-loader--relative global-loader--w50" v-else>
        <div class="loader"></div>
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
import InputText from "../../components/form/InputText";
import FormMixin from "@/mixins/FormMixin";
import errorHandler from "../../helpers/ErrorHandler";
import auth from "../../helpers/client-auth";
import { mapState } from "vuex";

export default {
  name: "ChangeEmailForm",
  data() {
    return {
      loading: false
    };
  },
  computed: {
    ...mapState("userStore", ["id", "email"])
  },
  mixins: [FormMixin],
  components: { InputText },
  methods: {
    async onSubmitEmail() {
      this.loading = true;
      const { email } = this.form;
      try {
        await auth.post("/api/account/changeEmail", {
          id: this.id,
          email
        });
        this.$toast.success(this.$t("email.success"));
      } catch (err) {
        if (err.response) {
          const { errors } = err.response.data;

          errorHandler(errors, this.$refs.form);
        }
      }
      this.loading = false;
    }
  }
};
</script>
