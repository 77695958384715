<template>
  <div class="center-container--flex" style="height: 100%;">
    <div class="center-container__middle">
      <div class="settings__container">
        <div class="h1 page-header">{{ $t("settings") }}</div>
        <div class="settings-tabs__container">
          <div
            class="settings-tabs__tab"
            :class="{ 'settings-tabs__tab--active': isActive === tab }"
            v-for="tab in tabs"
            :key="tab"
            @click="setIsActive(tab)"
          >
            {{ $t(`settings-tabs.${tab}`) }}
          </div>
        </div>
        <div class="settings__form-container">
          <profile-settings-form v-if="isActive === 'profile'"></profile-settings-form>
          <change-email-form v-if="isActive === 'change-email'"></change-email-form>
          <change-password-form v-if="isActive === 'change-password'"></change-password-form>
          <delete-account-form v-if="isActive === 'delete-account'"></delete-account-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ChangeEmailForm from "../components/settings/ChangeEmailForm";
import ProfileSettingsForm from "../components/settings/ProfileSettingsForm";
import ChangePasswordForm from "../components/settings/ChangePasswordForm";
import DeleteAccountForm from "../components/settings/DeleteAccountForm";

export default {
  name: "Settings",
  components: { DeleteAccountForm, ChangePasswordForm, ProfileSettingsForm, ChangeEmailForm },
  metaInfo: {
    title: "Settings"
  },
  data() {
    return {
      loading: true,
      tabs: ["profile", "change-password", "change-email", "delete-account"],
      isActive: this.$route.query.tab || "profile"
    };
  },
  computed: {
    ...mapState("userStore", ["id", "avatar", "fullName"])
  },
  methods: {
    setIsActive(tab) {
      this.isActive = tab;
      this.$router.push({ query: { tab } });
    }
  }
};
</script>
