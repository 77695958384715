<template>
  <ValidationObserver v-slot="{ handleSubmit }" ref="form">
    <form @submit.prevent="handleSubmit(onSubmitPassword)" class="settings-form__container">
      <div class="settings-form__desc">
        {{ $t("settings-change-password") }}
      </div>
      <div class="settings-form__row">
        <div class="settings-form__input-fields">
          <div class="settings-form__input-field">
            <InputText
              type="password"
              icon="hide"
              secondIcon="show"
              name="current_password"
              :placeholder="$t('password')"
              rules="required|min:8"
              @input="setValue"
            />
          </div>
          <div class="settings-form__input-field">
            <InputText
              type="password"
              icon="hide"
              secondIcon="show"
              name="password"
              :placeholder="$t('settings-new-password')"
              rules="required|min:8"
              @input="setValue"
            />
          </div>
          <div class="settings-form__input-field">
            <InputText
              type="password"
              icon="hide"
              secondIcon="show"
              name="password_confirmation"
              :placeholder="$t('settings-confirm-new-password')"
              rules="required|password:@password"
              @input="setValue"
            />
          </div>
        </div>
        <div class="settings-form__button">
          <InputButton :loading="loading" :text="$t('settings-change-button')" action="submit"></InputButton>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import InputText from "../../components/form/InputText";
import FormMixin from "@/mixins/FormMixin";
import errorHandler from "../../helpers/ErrorHandler";
import auth from "../../helpers/client-auth";
import { mapState } from "vuex";

export default {
  name: "ChangePasswordForm",
  data() {
    return {
      loading: false
    };
  },
  mixins: [FormMixin],
  components: { InputText },
  computed: {
    ...mapState("userStore", ["id", "avatar", "fullName"])
  },
  methods: {
    async onSubmitPassword() {
      this.loading = true;

      const { current_password, password, password_confirmation } = this.form;
      try {
        await auth.post("/api/account/changePassword", {
          id: this.id,
          current_password,
          password,
          password_confirmation
        });
        this.$toast.success(this.$t("password.success"));
      } catch (err) {
        if (err.response) {
          const { errors } = err.response.data;

          errorHandler(errors, this.$refs.form);
        }
      }
      this.loading = false;
    }
  }
};
</script>
