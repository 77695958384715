<template>
  <div class="form-input">
    <ValidationProvider
      ref="provider"
      :vid="name"
      :name="placeholder"
      :rules="rules"
      slim
      v-slot="{ errors }"
      mode="lazy"
    >
      <div class="form-input__wrapper">
        <input
          class="form-input__field"
          :class="{ 'has-error': errors.length }"
          :aria-label="placeholder"
          :type="inputType"
          :placeholder="placeholder"
          :value="value"
          @input="
            $emit('input', {
              name,
              value: $event.target.value
            })
          "
          :name="name"
          v-model="innerValue"
        />
        <span
          class="form-input__icon"
          :class="{ 'form-input__icon--pointer': secondIcon }"
          @click="changePasswordVisibility"
        >
          <inline-svg
            :src="require(`../../assets/icons/${showInputText ? secondIcon : icon}.svg`)"
            fill="currentColor"
            :aria-label="name"
          ></inline-svg>
        </span>
      </div>
      <div class="form-input__error" v-if="errors">
        {{ errors[0] }}
      </div>
    </ValidationProvider>
  </div>
</template>

<script>
import { ValidationProvider } from "vee-validate";

export default {
  name: "InputText",
  props: {
    type: String,
    icon: String,
    placeholder: String,
    name: String,
    value: String,
    rules: String,
    secondIcon: String
  },
  $_veeValidate: {
    value() {
      return this.value;
    }
  },
  data() {
    return {
      innerValue: null,
      showInputText: false
    };
  },
  methods: {
    changePasswordVisibility() {
      if (this.type !== "password") return;

      this.showInputText = !this.showInputText;
    }
  },
  computed: {
    inputType() {
      return this.showInputText ? "text" : this.type;
    }
  },
  components: {
    ValidationProvider
  }
};
</script>
