<template>
  <ValidationObserver v-slot="{ handleSubmit }" ref="form">
    <form @submit.prevent="handleSubmit(onDeleteAccount)" class="settings-form__container">
      <div class="settings-form__desc">
        {{ $t("settings-delete-account") }}
      </div>
      <div class="settings-form__row">
        <div class="settings-form__input-fields">
          <div class="settings-form__input-field">
            <InputText
              type="password"
              @input="setValue"
              icon="mail"
              name="password"
              :placeholder="$t('password')"
              rules="required"
            />
          </div>
        </div>

        <div class="settings-form__button">
          <InputButton :loading="loading" :text="$t('settings-confirm-button')" action="submit"></InputButton>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import InputText from "../../components/form/InputText";
import FormMixin from "@/mixins/FormMixin";
import errorHandler from "../../helpers/ErrorHandler";
import client from "../../helpers/client-auth";
import { mapState } from "vuex";
import DeleteAccountPopup from "@/components/popups/DeleteAccountPopup";

export default {
  name: "DeleteAccountForm",
  data() {
    return {
      loading: false
    };
  },
  mixins: [FormMixin],
  components: { InputText },
  computed: {
    ...mapState("userStore", ["id"])
  },
  methods: {
    async onDeleteAccount() {
      this.loading = true;

      const { password } = this.form;
      try {
        await client.post("/api/account/deleteAccount", {
          id: this.id,
          password
        });
        this.deleteAccountModal();
      } catch (err) {
        if (err.response) {
          const { messages, errors } = err.response.data;
          if (errors) {
            errorHandler(messages, this.$refs.form);
          } else {
            errorHandler({ password: messages }, this.$refs.form);
          }
        }
      }
      this.loading = false;
    },

    deleteAccountModal() {
      this.$modal.show(
        DeleteAccountPopup,
        {},
        {
          name: "deleteAccountPopup",
          adaptive: true,
          scrollable: true,
          classes: "default-popup",
          height: "auto",
          width: "35%",
          minWidth: 350,
          clickToClose: false
        }
      );
    }
  }
};
</script>
